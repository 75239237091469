import React, { useContext, useEffect, useState } from 'react';
import { SearchContext } from '../../../Context/SearchContext';
import { CartItemsContext } from '../../../Context/CartItemsContext';
import { WishItemsContext } from '../../../Context/WishItemsContext';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import './SearchPopupDesc.css';

const SearchPopupDesc = ({ toggleSearchPopup, isActive }) => {
    const { searchQuery, setSearchQuery } = useContext(SearchContext);
    const cartItemsContext = useContext(CartItemsContext);
    const wishItemsContext = useContext(WishItemsContext);
    const [searchTerm, setSearchTerm] = useState(searchQuery || '');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const apiBaseUrl = 'https://carabine.shop/api';

    useEffect(() => {
        setSearchQuery(searchTerm);

        const fetchData = async () => {
            setLoading(true);
            setError(null);

            try {
                let response;
                if (searchTerm.length >= 3) {
                    response = await fetch(`${apiBaseUrl}/search?query=${encodeURIComponent(searchTerm)}&limit=8`);
                } else {
                    response = await fetch(`${apiBaseUrl}/items?limit=8`);
                }

                if (!response.ok) {
                    throw new Error('Error fetching data');
                }

                const data = await response.json();
                setResults(data.slice(0, 6));
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [searchTerm, setSearchQuery]);

    const handleAddToWishList = (event, product) => {
        event.stopPropagation();
        event.preventDefault();

        const isInWishlist = wishItemsContext.items.some(item => item._id === product._id);

        if (isInWishlist) {
            wishItemsContext.removeItem(product._id);
        } else {
            let images = [];
            try {
                if (typeof product.image === 'string') {
                    images = JSON.parse(product.image);
                } else {
                    images = product.image || [];
                }
            } catch (e) {
                console.error('Error parsing images:', e);
            }

            const wishItem = {
                _id: product._id,
                name: product.name,
                price: product.price,
                image: images,
                category: product.category,
                size: product.size || "default",
                quantity: product.quantity || 0
            };
            wishItemsContext.addItem(wishItem);
        }
    };

    const handleAddToCart = (event, product) => {
        event.stopPropagation();
        event.preventDefault();
        
        if (product.quantity > 0) {
            let images = [];
            try {
                if (typeof product.image === 'string') {
                    images = JSON.parse(product.image);
                } else {
                    images = product.image || [];
                }
            } catch (e) {
                console.error('Error parsing images:', e);
            }

            const cartItem = {
                _id: product._id,
                name: product.name,
                price: product.price,
                image: images,
                category: product.category,
                size: product.size || "default",
                quantity: product.quantity || 0
            };
            cartItemsContext.addItem(cartItem, 1);
        }
    };

    const handleProductClick = (event, product) => {
        event.preventDefault();
        if (product.quantity > 0) {
            toggleSearchPopup();
            window.location.href = `/item/${product.category}/${product._id}`;
        }
    };

    return (
        <div className="search-popup desc">
            <div className="search-popup-close desc">
                <IconButton className="close-btn" onClick={toggleSearchPopup}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M10.5907 9L18 16.4093L16.4093 18L9 10.5907L1.59075 18L0 16.4093L7.40925 9L0 1.59075L1.59075 0L9 7.40925L16.4093 0L18 1.59075L10.5907 9Z" fill="#1B1B1B"></path>
                    </svg>
                </IconButton>
            </div>
            <div className={`popup-content ${isActive ? 'active' : ''}`}>
                <input
                    type="text"
                    placeholder="Искать"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className="search-btn">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.163967 17.5393L5.6114 12.0385C4.59609 10.8181 3.98416 9.24795 3.98416 7.53347C3.98416 3.64903 7.12175 0.5 10.9921 0.5C14.8624 0.5 18 3.64903 18 7.53347C18 11.4179 14.8624 14.5669 10.9921 14.5669C9.23365 14.5669 7.62744 13.9154 6.39685 12.8412L0.95702 18.3352C0.738092 18.5549 0.382914 18.5549 0.163986 18.3352C-0.0546608 18.1155 -0.0546608 17.7593 0.163986 17.5393H0.163967ZM10.9921 13.4509C14.2482 13.4509 16.888 10.8015 16.888 7.53347C16.888 4.26544 14.2482 1.61607 10.9921 1.61607C7.73592 1.61607 5.09618 4.26544 5.09618 7.53347C5.09618 10.8015 7.73592 13.4509 10.9921 13.4509Z" fill="#1B1B1B"/>
                    </svg>
                </button>

                {loading && <div className="loading">Загрузка...</div>}
                {searchTerm.length === 0 && (
                    <div className="placeholder-text">Введите запрос для начала поиска</div>
                )}
                {error && <div className="error-message">{error}</div>}

                {results.length > 0 ? (
                    <ul className="search-results desc">
                        {results.map((product) => {
                            const isInWishlist = wishItemsContext.items.some(item => item._id === product._id);
                            const isOutOfStock = product.quantity !== undefined && product.quantity === 0;
                            let images = [];
                            try {
                                if (typeof product.image === 'string') {
                                    images = JSON.parse(product.image);
                                } else {
                                    images = product.image || [];
                                }
                            } catch (e) {
                                console.error('Error parsing images:', e);
                            }

                            const firstImage = images.length > 0 
                                ? `${apiBaseUrl}/public/uploads/${images[0].filename}` 
                                : '';

                            return (
                                <li key={product._id} className={`search-result-item desc ${isOutOfStock ? 'out-of-stock' : ''}`}>
                                    <Link 
                                        to={isOutOfStock ? '#' : `/item/${product.category}/${product._id}`}
                                        className="product__link"
                                        onClick={(event) => handleProductClick(event, product)}
                                    >
                                        <div className="product-details desc">
                                            <div className="product__card__action">
                                                <IconButton
                                                    onClick={(event) => handleAddToWishList(event, product)}
                                                    sx={{ borderRadius: '20px', width: '40px' }}
                                                    disabled={isOutOfStock}
                                                >
                                                    <svg className={`addtowishlist ${isInWishlist ? 'active' : ''}`} width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17.4545 0.5C15.1636 0.5 13.1716 1.55768 12 3.33016C10.8284 1.55768 8.83636 0.5 6.54545 0.5C4.81011 0.502069 3.14642 1.20876 1.91935 2.46504C0.692279 3.72131 0.00202103 5.4246 0 7.20125C0 10.4625 1.98545 13.8567 5.90182 17.2877C7.69643 18.8533 9.63493 20.2373 11.6902 21.4202C11.7854 21.4726 11.8919 21.5 12 21.5C12.1081 21.5 12.2146 21.4726 12.3098 21.4202C14.3651 20.2373 16.3036 18.8533 18.0982 17.2877C22.0145 13.8567 24 10.4625 24 7.20125C23.998 5.4246 23.3077 3.72131 22.0806 2.46504C20.8536 1.20876 19.1899 0.502069 17.4545 0.5ZM12 20.0576C10.2098 18.9999 1.30909 13.4245 1.30909 7.20125C1.31053 5.77988 1.86269 4.41714 2.84438 3.41208C3.82608 2.40702 5.15713 1.84173 6.54545 1.84025C8.75782 1.84025 10.6156 3.04982 11.3945 4.99765C11.4439 5.12056 11.5277 5.22569 11.6356 5.29967C11.7434 5.37365 11.8702 5.41315 12 5.41315C12.1298 5.41315 12.2566 5.37365 12.3644 5.29967C12.4723 5.22569 12.5561 5.12056 12.6055 4.99765C13.3844 3.04982 15.2422 1.84025 17.4545 1.84025C18.8429 1.84173 20.1739 2.40702 21.1556 3.41208C22.1373 4.41714 22.6895 5.77988 22.6909 7.20125C22.6909 13.4245 13.7902 18.9999 12 20.0576Z" fill={isInWishlist ? "#FF0000" : "#1B1B1B"}/>
                                                    </svg>
                                                </IconButton>
                                                <IconButton
                                                    onClick={(event) => handleAddToCart(event, product)}
                                                    sx={{ borderRadius: '20px', width: '40px' }}
                                                    disabled={isOutOfStock}
                                                >
                                                    <svg className="addtocart" width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.30857 24.5C1.65048 24.5 1.10143 24.2947 0.661428 23.884C0.221428 23.4733 0.000952381 22.9609 0 22.3467V7.98667C0 7.37333 0.220476 6.86133 0.661428 6.45067C1.10238 6.04 1.65143 5.83422 2.30857 5.83333H5V5.16667C5 3.87067 5.48619 2.76889 6.45857 1.86133C7.43095 0.953778 8.61143 0.5 10 0.5C11.3886 0.5 12.569 0.953778 13.5414 1.86133C14.5138 2.76889 15 3.87067 15 5.16667V5.83333H17.6929C18.35 5.83333 18.8986 6.03911 19.3386 6.45067C19.7786 6.86222 19.999 7.37467 20 7.988V22.3467C20 22.96 19.7795 23.4724 19.3386 23.884C18.8976 24.2956 18.349 24.5009 17.6929 24.5H2.30857ZM2.30857 23.1667H17.6929C17.9119 23.1667 18.1133 23.0813 18.2971 22.9107C18.481 22.74 18.5724 22.5516 18.5714 22.3453V7.988C18.5714 7.78267 18.48 7.59422 18.2971 7.42267C18.1143 7.25111 17.9124 7.16578 17.6914 7.16667H15V10.5C15 10.6902 14.9319 10.8489 14.7957 10.976C14.6595 11.1031 14.4895 11.1667 14.2857 11.1667C14.0819 11.1667 13.9119 11.1031 13.7757 10.976C13.6395 10.8489 13.5714 10.6902 13.5714 10.5V7.16667H6.42857V10.5C6.42857 10.6902 6.36048 10.8489 6.22429 10.976C6.0881 11.1031 5.9181 11.1667 5.71429 11.1667C5.51048 11.1667 5.34048 11.1031 5.20429 10.976C5.06809 10.8489 5 10.6902 5 10.5V7.16667H2.30857C2.08857 7.16667 1.88667 7.252 1.70286 7.42267C1.51905 7.59333 1.42762 7.78178 1.42857 7.988V22.3467C1.42857 22.5511 1.52 22.7391 1.70286 22.9107C1.88571 23.0822 2.08714 23.1676 2.30714 23.1667M6.42857 5.83333H13.5714V5.16667C13.5714 4.228 13.2276 3.43778 12.54 2.796C11.8524 2.15422 11.0057 1.83333 10 1.83333C8.99429 1.83333 8.14762 2.15422 7.46 2.796C6.77238 3.43778 6.42857 4.228 6.42857 5.16667V5.83333Z" fill={isOutOfStock ? "#CCCCCC" : "#1B1B1B"}/>
                                                    </svg>
                                                </IconButton>
                                            </div>
                                            <div className="product__image">
                                                <div className="image-container">
                                                    {firstImage && <img src={firstImage} alt={product.name} className="product__img" />}
                                                    {isOutOfStock && (
                                                        <div className="out-of-stock-overlay">
                                                            <span>Нет в наличии</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="product__name">
                                                {product.name}
                                            </div>
                                            <div className="product__price">
                                                {isOutOfStock ? (
                                                    <span className="out-of-stock-text">Нет в наличии</span>
                                                ) : (
                                                    <span>{product.price}₽</span>
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                ) : searchTerm.length >= 3 && !loading && (
                    <div className="no-results">Ничего не найдено...</div>
                )}
            </div>
            <div className="overlays"></div>
        </div>
    );
};

export default SearchPopupDesc;